<template>
  <form method="POST" @submit.prevent="onSubmit" class="tw-space-y-6">
    <SignupError v-if="error_msg" :message="error_msg" />
    <div class="tw-space-y-1">
      <div class="tw-mt-1">
        <input
          v-model="email"
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          autocomplete="email"
          class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
          v-bind:class="[
            errors.includes('email') ? 'tw-border tw-border-red-500' : '',
          ]"
        />
      </div>
    </div>

    <div>
      <button
        type="submit"
        class="tw-w-full tw-flex tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white lg:tw-bg-black-500 tw-bg-orange-500 lg:hover:tw-bg-black-300 hover:tw-bg-orange-600 focus:tw-outline-none"
        v-bind:class="[isLoading ? 'disabled:opacity-50' : '']"
      >
        <span :class="[isLoading ? 'd-none' : '']">Send a reset link</span>
        <clip-loader
          :loading="isLoading"
          color="#f8f8f8"
          size="15px"
        ></clip-loader>
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import LoginContainer from "@/views/Auth/LoginContainer";
import SignupError from "@/components/Auth/SignupError";

export default {
  name: "Register",
  components: {
    ClipLoader,
    LoginContainer,
    SignupError
  },
  data() {
    return {
      email: "",
      isLoading: false,
      errors: "",
      error_msg: "",
      error_res: null,
    };
  },
  computed: {},
  methods: {
    validateForm() {
      this.errors = []; // clear existing errors

      if (this.email === null || this.email === "" || this.email.length < 1) {
        this.errors.push("email");
      }

      if (this.errors.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit() {
      if (this.isLoading === false && this.validateForm()) {
        this.sendResetRequest();
      }
    },
    async sendResetRequest() {
      this.isLoading = true
      axios
        .post(
          process.env.VUE_APP_API_ROOT + "accounts/reset_password/",
          this.$data
        )
        .then((response) => {
          // this.$router.push({ name: "Login" });
          this.isLoading = false
          this.$emit('success')
        })
        .catch((error) => {
          // handle authentication and validation errors here
          this.error_res = "Unable to reset password at this time. Please contact riskfacilitator support.";
          this.isLoading = false;
        });
    },
  },
};
</script>
