<template>
  <div>
    <div
      class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-32 tw-w-32"
    >
      <EmailSent> </EmailSent>
    </div>
    <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
      <h3
        class="tw-text-lg tw-leading-6 tw-font-medium lg:tw-text-gray-900 tw-text-white"
        id="modal-headline"
      >
        Please check your email
      </h3>
      <div class="tw-mt-2">
        <p class="tw-text-sm tw-text-gray-400 lg:tw-text-gray-500">
          Please click the link we've sent to your email to reset your password.
          Set a new password as soon as possible, as this link will expire soon.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import EmailSent from "../ui/Illustrations/EmailSent.vue";

export default {
  components: { EmailSent },
};
</script>

<style>
</style>