<template>
<div>
  <div v-show="showBanner" class="tw-relative tw-bg-orange-600">
    <div class="tw-max-w-7xl tw-mx-auto tw-py-3 tw-px-3 sm:tw-px-6 lg:tw-px-8">
      <div class="tw-pr-16 sm:tw-text-center sm:tw-px-16">
        <p class="tw-mb-0 tw-font-medium tw-text-white">
          <span class="tw-inline"> To make using risky.live simpler, we've updated our sign in so you can now login using your email.</span>
          <!-- <span class="tw-block sm:tw-ml-2 sm:tw-inline-block">
            <a href="#" class="tw-text-white tw-font-bold tw-underline"> Learn more <span aria-hidden="true">&rarr;</span></a>
          </span> -->
        </p>
      </div>
      <div class="tw-absolute tw-inset-y-0 tw-right-0 tw-pt-1 tw-pr-1 tw-flex tw-items-start sm:tw-pt-1 sm:tw-pr-2 sm:tw-items-start">
        <button type="button" @click="showBanner = false" class="tw-flex tw-p-2 tw-rounded-md hover:tw-bg-orange-500 focus:tw-outline-none">
          <span class="tw-sr-only">Dismiss</span>
          <icon :icon="['fal', 'times']" class="tw-h-6 tw-w-6 tw-text-white" size="2x"></icon> 
        </button>
      </div>
    </div>
  </div>
  <div class="tw-min-h-screen lg:tw-bg-white tw-bg-black tw-flex">
    <div
      class="tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24"
    >
      <div class="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
          <slot/>
      </div>
    </div>
    <div class="tw-hidden lg:tw-block tw-relative tw-w-0 tw-flex-1 tw-bg-black">
      <img
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
        src="@/assets/images/bg-login.png"
        alt=""
      />
    </div>
  </div>
</div>
</template>

<script>
import LoginForm from "@/components/Auth/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  data() {
    return {
      showBanner: true,
    };
  }
};
</script>