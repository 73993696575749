<template>
    <form method="POST" @submit.prevent="validateForm" class="tw-space-y-6">
        <incorrect-login-alert v-if="isInvalidCredentials"></incorrect-login-alert>
        <div>
            <div class="tw-mt-1">
                <!-- <vue-tel-input
                    v-model="username"
                    placeholder="Enter your mobile number"
                    mode="international"
                    :preferredCountries="['AU', 'US', 'GB', 'NZ']"
                    inputClasses="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 sm:tw-text-sm "
                    wrapperClasses="tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200"
                    v-bind:style="[
                        errors.includes('username')
                            ? {
                                  'border-width': '1px !important',
                                  'border-color': 'rgba(239, 68, 68, 1) !important',
                              }
                            : { 'border-width': '0 !important' },
                    ]"
                ></vue-tel-input> -->
                <input
                    v-model="username"
                    id="username"
                    name="username"
                    type="username"
                    placeholder="someone@yourcompany.com"
                    autocomplete="username"
                    class="tw-block tw-w-full tw-px-4 tw-py-3 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 lg:tw-bg-gray-100 tw-bg-black-200 lg:tw-text-black-500 tw-rounded-md tw-appearance-none focus:tw-outline-none sm:tw-text-sm"
                    v-bind:class="[ errors.includes('username') ? 'tw-border tw-border-red-500' : '']"
                />
            </div>
        </div>

        <div class="tw-space-y-1">
            <div class="tw-mt-1">
                <input
                    v-model="password"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    autocomplete="current-password"
                    class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-100 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
                    v-bind:class="[
                        errors.includes('password')
                            ? 'tw-border tw-border-red-500'
                            : '',
                    ]"
                />
            </div>
        </div>
        <div>
            <button
                type="submit"
                class="tw-w-full tw-flex tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white lg:tw-bg-black-500 tw-bg-orange-500 lg:hover:tw-bg-black-300 hover:tw-bg-orange-600 focus:tw-outline-none"
                v-bind:class="[isLoading ? 'disabled:opacity-50' : '']"
            >
                <span :class="[isLoading ? 'd-none' : '']">Sign in</span>
                <clip-loader
                    :loading="isLoading"
                    color="#f8f8f8"
                    size="15px"
                ></clip-loader>
            </button>
        </div>
    </form>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { VueTelInput } from 'vue-tel-input'
import IncorrectLoginAlert from '@/components/Auth/IncorrectLoginAlert.vue'

export default {
    name: 'LoginForm',
    components: {
        ClipLoader,
        VueTelInput,
        IncorrectLoginAlert,
    },
    data() {
        return {
            username: '',
            password: '',
            isLoading: false,
            isInvalidCredentials: false,
            errors: [],
        }
    },
    computed: {},
    methods: {
        validateForm() {
            this.errors = []
            const errors = []
            const mobileRegex = /^[\d+][\d- ]+/

            if (!this.username) {
                errors.push('username')
            }

            if (!this.password) {
                errors.push('password')
            }

            if (errors.length === 0) {
                return this.login()
            }

            this.errors = errors
            return
        },
        login() {
            this.isInvalidCredentials = false
            let username = this.username
            let password = this.password

            if (this.isLoading === false) {
                // Don't allow concurrent requests
                this.isLoading = true
                this.$store
                    .dispatch('auth/login', { email: username, password })
                    .then(e => {
                        this.$router.push('/incidents')
                    })
                    .catch(err => {
                        this.isInvalidCredentials = true
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
    },
    mounted() {},
}
</script>

<style>
.vue-tel-input {
    border-radius: 0.375rem !important;
}
.vti__input {
    border-radius: 0.375rem !important;
    border: unset !important;
}
.vue-tel-input:focus-within {
    box-shadow: none !important;
}
</style>
