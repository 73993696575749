<template>
  <div class="tw-mt-8 tw-rounded-md lg:tw-bg-red-50 tw-bg-red-900 tw-p-4">
    <div class="tw-flex">
      <div class="tw-flex-shrink-0">
        <svg
          class="tw-h-5 tw-w-5 tw-text-red-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="tw-ml-3">
        <h3 class="tw-text-sm tw-leading-5 tw-font-medium lg:tw-text-red-800 tw-text-red-50 mb-0">
          Your username or password is incorrect.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>